import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { constants } from '@wsb/guac-widget-core';

const { renderModes } = constants;

const REAMAZE_JS_CONTAINER_ID = 'reamazejs-container';

function ReamazeWidget(props) {
  const {
    position,
    prompt,
    promptEnabled,
    color,
    reamazeBrandId,
    reamazeWrap,
    reamazeJsSource,
    reamazeCookieJsSource,
    cookieBannerEnabled,
    confirmationMessage,
    avatar,
    forceShowFlyout,
    renderMode,
    isMobile
  } = props;

  const [firstRenderGreeting, setFirstRenderGreeting] = useState(true);
  const [firstRenderConfirmation, setFirstRenderConfirmation] = useState(true);

  const iframeId = 'reamaze-iframe' + (isMobile ? '-mobile' : '');
  const iframeWrapId = 'reamaze-iframe-wrapper' + (isMobile ? '-mobile' : '');

  const getReamazeJs = doc => {
    const reamazejs = doc.createElement('script');
    reamazejs.src =
      renderMode !== renderModes.EDIT && renderMode !== renderModes.PREVIEW && cookieBannerEnabled
        ? reamazeCookieJsSource
        : reamazeJsSource;
    reamazejs.async = true;
    reamazejs.type = 'text/javascript';
    return reamazejs;
  };

  const buildCueObject = text => {
    return {
      mode: 'notification',
      delay: renderMode === renderModes.EDIT ? 0 : 10,
      text
    };
  };

  const getSupportObject = () => {
    return {
      account: reamazeBrandId,
      openPopup: forceShowFlyout,
      ui: {
        contactMode: 'default',
        enableKb: 'false',
        styles: {
          widgetColor: color
        },
        widget: {
          icon: 'webChat',
          label: promptEnabled && renderMode !== renderModes.EDIT ? buildCueObject(prompt) : false,
          position: position
        },
        shoutboxFacesMode: 'custom',
        overrides: {
          confirmationMessage: confirmationMessage
        }
      },
      user: {
        authpath: `/m/api/reamaze/v2/customers/auth?brand=${reamazeBrandId}`
      },
      _preview: forceShowFlyout
    };
  };

  const iframeClick = () => {
    const iframe = document.getElementById(iframeId);
    const doc = iframe.contentWindow.document;
    const widget = doc.getElementById('reamaze-widget');

    const event = doc.createEvent('HTMLEvents');
    event.initEvent('click', true, false);
    widget.dispatchEvent(event);
  };

  const createIframe = () => {
    // mega hacks here, essentially we're creating the widget with an iframe because only one widget can be rendered per page
    // the iframe and iframe wrapper cover the entire website 'preview'
    // then we have a fake button that goes on top of these that listens for clicks to then click the widget within the iframe

    const iframe = document.createElement('iframe');
    iframe.id = iframeId;
    iframe.src = 'about:blank';
    iframe.height = '100%';
    iframe.width = '100%';
    iframe.style.cssText = 'pointer-events:none; position:absolute; border:none;';

    const realButton = document.createElement('div');
    realButton.style.cssText = 'pointer-events:auto;';
    realButton.addEventListener('click', iframeClick);

    const fakeButton = document.createElement('div');
    fakeButton.id = 'fake-button';
    fakeButton.style.cssText = 'position:absolute; right: 0; bottom: 0; height: 60px; width: 60px';
    fakeButton.addEventListener('mouseenter', () => {
      fakeButton.style.cssText += 'cursor:pointer';
    });
    fakeButton.addEventListener('click', e => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('click', true, false);
      realButton.dispatchEvent(event);
      e.stopPropagation();
    });

    const wrap = document.getElementById(iframeWrapId);
    wrap.appendChild(iframe);
    wrap.parentNode.insertBefore(fakeButton, wrap);

    iframe.addEventListener('load', () => {
      const doc = iframe.contentDocument;
      const win = iframe.contentWindow;
      win._support = getSupportObject();

      doc.open();
      doc.close();
      const reamazejs = getReamazeJs(doc);
      doc.head.appendChild(reamazejs);
      doc.body.appendChild(realButton);
    });
  };

  const mutationObserverCallback = (mutationList, observer) => {
    mutationList.forEach(mutation => {
      const node = mutation.addedNodes[0];

      if (node?.id === REAMAZE_JS_CONTAINER_ID) {
        if (renderMode === renderModes.EDIT && reamazeWrap?.current) {
          // in editor, move the widget to appear inside the frame
          reamazeWrap.current.append(node);
          observer.disconnect();
          return;
        } else if (renderMode === renderModes.PREVIEW) {
          // in preview sometimes the widget will load, don't want that
          node.remove();
          observer.disconnect();
          return;
        }
      }
    });
  };

  useEffect(() => {
    if (renderMode === renderModes.EDIT || renderMode === renderModes.PREVIEW) {
      const observer = new MutationObserver(mutationObserverCallback);
      const observerOptions = { childList: true };
      observer.observe(document.body, observerOptions);
    }

    if (renderMode === renderModes.PREVIEW) {
      createIframe();
    } else {
      const reamazejs = getReamazeJs(document);
      document.body.appendChild(reamazejs);
      window._support = getSupportObject();
    }

    return () => {
      const rmz = document.querySelector(`script[src='${reamazeJsSource}']`);
      if (rmz) rmz.remove();

      const reamazeContainer = document.getElementById(REAMAZE_JS_CONTAINER_ID);
      if (reamazeContainer) reamazeContainer.remove();
    };
  }, []);

  useEffect(() => {
    if (renderMode !== renderModes.EDIT) return;

    window._support.ui.widget.position = position;
    window._support.ui.styles = { widgetColor: color };
    window._support.ui.shoutboxFacesMode = 'custom';
    window._support.openPopup = forceShowFlyout;
    window._support._preview = forceShowFlyout;
    window._support._route = null;

    // We want to hide the label when in EDIT mode so it
    // doesn't block any content on the preview portion
    window._support.ui.widget.label = false;

    if (typeof window.reamaze !== 'undefined') {
      window.reamaze.reload();
    }
  }, [position, color, avatar, forceShowFlyout]);

  useEffect(() => {
    if (renderMode !== renderModes.EDIT) return;

    if (firstRenderGreeting) {
      setFirstRenderGreeting(false);
      return;
    }

    if (promptEnabled && forceShowFlyout) {
      window._support.ui.widget.label = buildCueObject(prompt);
    } else {
      window._support.ui.widget.label = false;
    }

    window._support.openPopup = false;

    if (typeof window.reamaze !== 'undefined') {
      window.reamaze.reload();
    }
  }, [prompt, promptEnabled]);

  useEffect(() => {
    if (renderMode !== renderModes.EDIT) return;

    if (firstRenderConfirmation) {
      setFirstRenderConfirmation(false);
      return;
    }

    window._support._route = 'conversations:show';
    window._support._routeParams = { slug: '*mock_confirm_convo*' };
    window._support.ui.overrides.confirmationMessage = confirmationMessage;
    window._support.openPopup = forceShowFlyout;
    window._support._preview = forceShowFlyout;

    if (typeof window.reamaze !== 'undefined') {
      window.reamaze.reload();
    }
  }, [confirmationMessage]);

  return (
    <div>
      { renderMode === renderModes.PREVIEW && (
        <div
          id={ iframeWrapId }
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            pointerEvents: 'none'
          }}
        ></div>
      ) }
    </div>
  );
}

ReamazeWidget.propTypes = {
  position: PropTypes.string,
  prompt: PropTypes.string,
  promptEnabled: PropTypes.bool,
  color: PropTypes.string,
  reamazeBrandId: PropTypes.string,
  reamazeJsSource: PropTypes.string,
  reamazeCookieJsSource: PropTypes.string,
  reamazeWrap: PropTypes.node,
  confirmationMessage: PropTypes.string,
  avatar: PropTypes.string,
  forceShowFlyout: PropTypes.bool,
  renderMode: PropTypes.string,
  isMobile: PropTypes.bool,
  cookieBannerEnabled: PropTypes.bool
};

export default ReamazeWidget;
